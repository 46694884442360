@import "../../assets/css/variables.scss";

.hero_main {
  height: 100%;
  min-height: calc(100vh - 265px);
}

.hero_img {
  position: relative;
  width: 100%;
  max-width: 400px;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(9, 9, 9, 0) 66.67%,
      #0d0d0d 96.88%
    );
  }
}

.social_links {
  writing-mode: vertical-rl;
  column-gap: 90px;
  a {
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
    transition: 200ms ease-in-out;
    svg {
      fill: #ffffff4d;
      transition: 200ms ease-in-out;
    }
    span {
      color: #ffffff4d;
      transition: 200ms ease-in-out;
      &::after {
        content: "";
      }
    }
    &:first-child,
    &:last-child {
      &:hover {
        column-gap: 16px;
        svg {
          scale: 2;
          fill: $text-blue;
        }
        span {
          color: $text-blue;
        }
      }
    }
    &:nth-child(2) {
      &:hover {
        svg {
          scale: 2;
          fill: #b604e4;
        }
        span {
          color: #b604e4;
        }
      }
    }
  }
}

.heading {
  margin-top: 80px;
  h1 {
    margin-bottom: 0;
    font-family: "Arimo", sans-serif;
    font-size: 76px;
    font-weight: 700;
    .blur {
      color: transparent;
      background: transparent;
      animation: tab1blur 2s ease-out;
      -webkit-animation: tab1blur 2s ease-out;
      animation-fill-mode: forwards;
      &:nth-child(1) {
        animation-delay: 0.1s;
        -webkit-animation-delay: 0.1s;
      }
      &:nth-child(2) {
        animation-delay: 0.2s;
        -webkit-animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.3s;
        -webkit-animation-delay: 0.3s;
      }
      &:last-child {
        animation-delay: 0.4s;
        -webkit-animation-delay: 0.4s;
      }
    }
    .gradient {
      font-family: "Arimo", sans-serif;
      animation: none;
      // -webkit-animation: tab1gradienrblur 2s ease-out;
      animation-fill-mode: forwards;
      color: transparent;
      background: $gradient-background;
      -webkit-text-stroke: 4px transparent;
      -webkit-background-clip: text;
      color: #0d0d0d;
    }
    .tab2gradient {
      font-family: "Arimo", sans-serif;
      animation: none;
      // -webkit-animation: tab1gradienrblur 2s ease-out;
      animation-fill-mode: forwards;
      color: transparent;
      background: $gradient-background;
      -webkit-text-stroke: 4px transparent;
      -webkit-background-clip: text;
      color: #0d0d0d;
      animation-delay: 0.4s;
      -webkit-animation-delay: 0.4s;
    }
  }
  h2 {
    font-size: 64px;
    color: $white-color;
    font-weight: 500;
    text-align: center;
    font-family: "Arimo", sans-serif;
    .blur {
      color: transparent;
      background: transparent;
      animation: tab1blur 2s ease-out;
      -webkit-animation: tab1blur 2s ease-out;
      animation-fill-mode: forwards;
      &:nth-child(1) {
        animation-delay: 0.1s;
        -webkit-animation-delay: 0.1s;
      }
      &:nth-child(2) {
        animation-delay: 0.2s;
        -webkit-animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.3s;
        -webkit-animation-delay: 0.3s;
      }
      &:nth-child(4) {
        animation-delay: 0.4s;
        -webkit-animation-delay: 0.4s;
      }
      &:last-child {
        animation-delay: 0.5s;
        -webkit-animation-delay: 0.5s;
      }
    }
  }

  p {
    text-align: center;
    margin: 30px 0;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 1.8;
  }
  .tab2_para {
    max-width: 918px;
  }
  .tab3_para {
    max-width: 1070px;
  }
}

.tab_button_main {
  row-gap: 125px;
  .tab_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 70px;
    aspect-ratio: 1;
    border-radius: 100%;
    background-color: transparent;
    button {
      color: white;
      border: none;
      background-color: transparent;
      border-radius: 100%;
    }
  }
  .active_tab_btn {
    background-color: #006eda1a;
    button {
      color: #006eda;
    }
  }
}

@media (max-width: 1199.98px) {
  .hero_img {
    max-width: 350px;
  }

  .heading {
    margin-top: 60px;
    h1 {
      font-size: 56px;
    }
    h2 {
      font-size: 40px;
      color: $text-blue;
      font-weight: 500;
      text-align: center;
      font-family: "Arimo", sans-serif;
    }
    p {
      margin: 15px 0;
      font-size: 16px;
      line-height: 1.3;
    }
  }

  .tab_button_main {
    row-gap: 90px;
    .tab_button {
      max-width: 50px;
      min-width: 50px;
    }
  }
}

@media (max-width: 767.98px) {
  .hero_img {
    max-width: 300px;
  }

  .hero_main {
    min-height: auto;
  }
  .heading {
    margin-top: 40px;
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 30px;
    }
  }
  .tab_button_main {
    column-gap: 80px;
    .tab_button {
      max-width: 50px;
      min-width: 50px;
    }
  }
}

@media (max-width: 575.98px) {
  .hero_img {
    max-width: 250px;
  }

  .hero_main {
    padding: 0 20px;
  }
  .heading {
    margin-top: 20px;
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
  }
  .tab_button_main {
    column-gap: 40px;
    .tab_button {
      max-width: 40px;
      min-width: 40px;
    }
  }
}

@keyframes tab1blur {
  0% {
    text-shadow: 0 0 100px #fff;
    opacity: 0;
  }
  5% {
    text-shadow: 0 0 90px #fff;
  }
  100% {
    text-shadow: 0 0 0px #fff;
    opacity: 1;
  }
}

@keyframes tab1gradienrblur {
  0% {
    opacity: 0;
    text-shadow: 0 0 100px #fff;
  }
  5% {
    text-shadow: 0 0 90px #fff;
  }
  100% {
    opacity: 1;
  }
}
