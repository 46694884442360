@import "../../assets/css/variables.scss";

.main {
  width: 100%;
  max-width: 980px;
  margin: 120px auto 0;
  overflow-x: hidden;
  .heading {
    text-align: center;
    width: fit-content;
    margin: 0 auto 40px;
    p {
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 4.2px;
    }
    h3 {
      font-size: 50px;
      font-family: "Arimo", sans-serif;
    }
  }
}

.story_main {
  .heading {
    text-align: center;
    width: fit-content;
    margin: 0 auto 40px;
    h3 {
      font-size: 50px;
      font-family: "Arimo", sans-serif;
    }
  }
  h6 {
    margin-top: 40px;
    text-align: start;
    font-size: 30px;
  }
}

.content {
  div {
    padding-top: 50px;
    width: 100%;
    max-width: 430px;
    position: relative;
    h6 {
      font-size: 30px;
      font-weight: 500;
      position: relative;
      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background: $gradient-background;
        border-radius: 100%;
        z-index: 1;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 36px;
          height: 36px;
          background: $gradient-background;
          border-radius: 100%;
          z-index: 1;
          opacity: 0.3;
        }
      }
    }
    p {
      font-size: 18px;
      opacity: 0.75;
      line-height: 1.6;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: $text-blue;
    }
    &:nth-child(odd) {
      text-align: end;
      h6 {
        span {
          right: -72px;
        }
      }
      &::after {
        right: -61px;
      }
    }
    &:nth-child(even) {
      margin-left: auto;
      text-align: start;
      h6 {
        span {
          left: -72px;
        }
      }
      &::after {
        left: -61px;
      }
    }
    &:last-child {
      p {
        padding-bottom: 20px;
      }
    }
  }
}

.status {
  gap: 20px;
  flex-wrap: wrap;
  margin: 120px 48px;
  padding: 72px 90px;
  border: 2px solid $border-color-3;
  border-radius: 20px;
  div {
    width: 100%;
    max-width: 200px;
    h5,
    p {
      font-family: "Arimo", sans-serif;
    }
    h5 {
      font-size: 50px;
      font-weight: 700;
      span {
        background: $gradient-background;
        -webkit-background-clip: text;
        -webkit-text-stroke: 4px transparent;
        color: #171119;
      }
    }
    p {
      font-size: 18px;
      text-transform: capitalize;
      font-weight: 500;
    }
    &:nth-child(odd) {
      p {
        color: $white-color;
        opacity: 0.75;
      }
    }
    &:nth-child(even) {
      p {
        color: $white-color;
        opacity: 0.75;
      }
    }
  }
}

.story {
  p {
    line-height: 1.6;
    font-size: 18px;
  }
}

.company_slider {
  padding: 140px 0;
}

@media (max-width: 1399.98px) {
  .status {
    padding: 60px 80px;
  }
}

@media (max-width: 1199.98px) {
  .status {
    margin: 100px 24px;
    padding: 50px;
    div {
      h5 {
        font-size: 40px;
      }
    }
  }
  .company_slider {
    padding: 120px 0;
  }
}

@media (max-width: 991.98px) {
  .main {
    margin: 80px auto 0;
    .heading {
      margin: 0 auto 50px;
    }
  }
  .content {
    div {
      &:nth-child(odd) {
        margin: 0 auto;
        text-align: start;
        h6 {
          span {
            right: calc(100% + 48px);
          }
        }
        &::after {
          right: calc(100% + 59px);
        }
      }
      &:nth-child(even) {
        margin: 0 auto;
        text-align: start;
      }
    }
  }
  .status {
    margin: 60px 24px;
    padding: 40px;
    div {
      h5 {
        font-size: 36px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .company_slider {
    padding: 100px 0;
  }
  .story {
    p {
      font-size: 16px;
    }
  }
}

@media (max-width: 767.98px) {
  .status {
    margin: 80px 20px;
  }
  .main {
    margin: 60px auto 0;
    .heading {
      margin: 0 auto 20px;
    }
  }
  .content {
    padding: 20px;
    div {
      h6 {
        font-size: 26px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .status {
    padding: 30px;
  }
  .company_slider {
    padding: 80px 0;
  }
}

@media (max-width: 600px) {
  .content {
    padding: 20px;
    div {
      &:nth-child(odd) {
        margin: 0 0 0 auto;
      }
      &:nth-child(even) {
        margin: 0 0 0 auto;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .status {
    margin: 60px 20px 80px 20px;
  }
  .main {
    margin: 40px auto 0;
    .heading {
      margin: 0 auto;
      h3 {
        font-size: 40px;
      }
    }
  }
  .content {
    padding: 20px;
    div {
      max-width: 100%;
      padding-top: 30px;
      &::after {
        content: none;
      }
      h6 {
        span {
          display: none;
        }
      }
    }
  }
  .status {
    padding: 20px;
  }
  .company_slider {
    padding: 60px 0;
  }
  .story {
    p {
      font-size: 14px;
    }
  }
}
