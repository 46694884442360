@import "./variables.scss";

.main {
  column-gap: 50px;
}

.sidebar_open {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid white;
  rotate: 180deg;
  svg {
    rotate: 180deg;
    max-width: 40px;
  }
}

.sidebar_close {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid white;
  svg {
    max-width: 40px;
  }
}

.description {
  margin-top: 40px;
  h3 {
    font-size: 35px;
    font-family: "Arimo", sans-serif;
    font-weight: 700;
  }
  p {
    max-width: 1140px;
    font-size: 18px;
    font-weight: 400;
    color: $text-color;
    letter-spacing: 0.5px;
  }
  .quote {
    margin: 60px 0;
    display: flex;
    column-gap: 45px;
    div {
      max-width: 50px;
    }
    .text {
      max-width: 685px;
      em {
        margin-bottom: 24px;
        font-size: 26px;
        font-weight: 400;
      }
      h6 {
        margin-bottom: 0;
        color: $text-blue;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 3.24px;
        text-transform: uppercase;
      }
    }
  }
}

.author_details {
  display: flex;
  column-gap: 30px;
  margin-top: 60px;
  padding: 40px;
  background-color: #171717;
  border-radius: 20px;
  .img {
    max-width: 150px;
  }
  .detail {
    h5 {
      font-family: "Arimo", sans-serif;
      font-size: 20px;
      font-weight: 700;
    }
    p {
      margin: 14px 0 20px;
      color: $text-color;
      font-size: 18px;
      max-width: 560px;
    }
  }
}

.pagination {
  margin: 80px 0 180px;
  .arrow {
    cursor: pointer;
    .arrow_txt {
      color: $text-blue;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1.96px;
    }
  }
}

.comments {
  margin-bottom: 80px;
  h4 {
    font-family: "Arimo", sans-serif;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 60px;
  }
  div {
    row-gap: 40px;
  }
}

.bottomMain {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.main {
  width: 100%;
  max-width: 1440px;
  margin: 80px auto 60px;
  .heading {
    font-family: "Arimo", sans-serif;
    font-size: 36px;
    font-weight: 700;
  }
  .contents {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 30px;
    p {
      margin-bottom: 0;
      color: #bac1d4;
    }
    i {
      color: #006eda;
      font-weight: 700;
    }
    .imgContainer {
      img {
        width: 100%;
        height: auto;
        border-radius: 20px;
      }
    }
  }
}

.assetsMain {
  margin: 80px 0;
  .blogAssets {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 30px;
  }
}

.subHead {
  margin-top: 80px;
  h5 {
    font-size: 24px;
  }
}

.points {
  margin: 16px 0;
  list-style: none;
  counter-reset: li;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-left: 0;
  li {
    counter-increment: li;
    display: flex;
    gap: 6px;
    &::before {
      content: counter(li) ".";
      color: #bac1d4;
      display: inline-block;
      width: 1em;
    }
    p {
      color: #bac1d4;
      span {
        font-weight: 600;
      }
    }
  }
}

.quote {
  margin: 40px 0;
  display: flex;
  column-gap: 30px;
  .quoteLogo {
    width: 100%;
    max-width: 40px;
  }
  .text {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    i {
      font-size: 24px;
    }
    p {
      font-size: 22px;
      color: #006eda;
      font-weight: 600;
    }
  }
}

@media (max-width: 1199.98px) {
  .subHead {
    margin-top: 60px;
  }

  .assetsMain {
    margin: 60px 0;
  }

  .main {
    .heading {
      font-size: 30px;
    }
  }

  .main {
    margin: 60px auto;
  }

  .main {
    column-gap: 30px;
  }

  .pagination {
    margin: 80px 0 140px;
  }
}

@media (max-width: 991.98px) {
  .main {
    .contents {
      margin-top: 20px;
    }
  }

  .subHead {
    margin-top: 40px;
  }

  .assetsMain {
    margin: 40px 0;
  }

  .description {
    margin-top: 30px;
    h3 {
      font-size: 30px;
    }
  }
  .pagination {
    margin: 60px 0 100px;
  }

  .comments {
    margin-bottom: 70px;
    h4 {
      font-size: 30px;
      margin-bottom: 45px;
    }
    div {
      row-gap: 30px;
    }
  }

  .quote {
    margin: 30px 0;
    .text {
      i {
        font-size: 20px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .main {
    .heading {
      font-size: 26px;
    }
  }

  .main {
    margin: 40px auto;
  }

  .description {
    margin-top: 20px;
    h3 {
      font-size: 26px;
    }
    p {
      font-size: 16px;
    }
  }

  .pagination {
    margin: 0px 0 60px;
  }
}

@media (max-width: 575.98px) {
  .quote {
    margin: 20px 0;
    .text {
      i {
        font-size: 16px;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }

  .subHead {
    margin-top: 20px;
  }

  .assetsMain {
    margin: 20px 0;
  }

  .description {
    margin-top: 20px;
    h3 {
      font-size: 22px;
    }
    p {
      font-size: 14px;
    }
  }
  .sidebar_open {
    width: 50px;
    height: 50px;
    svg {
      max-width: 30px;
    }
  }

  .comments {
    margin-bottom: 60px;
    h4 {
      font-size: 26px;
      margin-bottom: 30px;
    }
    div {
      row-gap: 20px;
    }
  }
}

@media (max-width: 375.98px) {
  .main {
    .heading {
      font-size: 24px;
    }
  }

  .main {
    margin: 20px auto;
  }
}
