@import "../../assets/css//variables.scss";

.footer {
  background-color: #1c1c1c;
  color: $white-color;
  padding: 100px 0 20px;
  .get_in_touch {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #292929;
    border-radius: 20px;
    margin-bottom: 60px;
    flex-wrap: wrap;
    gap: 20px;
    padding: 30px;
    h1 {
      text-align: center;
      font-size: 90px;
      font-weight: 800;
      background: var(
        --gradient,
        linear-gradient(133deg, #006eda 30.73%, #723582 50.52%, #db0030 72.92%)
      );
      font-style: normal;
      font-weight: 700;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: transparent;
      font-family: "Arimo", sans-serif;
      .arrow_icon {
        fill: $gradient-background;
      }
    }
    .arrowImg {
      width: 100%;
      max-width: 100px;
    }
  }
  .footer_list {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    font-family: "Rubik", sans-serif;
    img {
      width: 100%;
    }
    h4 {
      font-size: 18px;
      letter-spacing: 3px;
      margin-bottom: 24px;
    }
    ul {
      padding: 0;
      li {
        list-style: none;
        margin-bottom: 15px;
        a,
        p {
          color: #7d7d7d;
          text-decoration: none;
        }
      }
    }
    input {
      background: transparent;
      border: 0;
      border-bottom: solid 1px #fff;
      padding: 15px 5px;
      color: $white-color;
      &:focus-visible {
        outline: 0;
      }
    }
    button {
      margin-top: 25px;
      color: $white-color;
      background-color: transparent;
      border: 0;
      font-family: "Rubik", sans-serif;
      font-weight: normal;
      text-transform: uppercase;
      .subscribe_icon {
        font-size: 18px;
        margin-left: 2px;
      }
    }
  }
  .copyright_sec {
    margin-top: 35px;
    .social_icons {
      a {
        text-decoration: none;
        svg {
          margin-right: 20px;
          font-size: 18px;
          fill: $text-blue;
        }
      }
    }
  }
}

.watch {
  display: block;
  text-align: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
}

.clock {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.calgary_clock {
  color: #7d7d7d;
  text-transform: capitalize;
  text-align: center;
  margin: 12px 0 18px;
}

@media (max-width: 991.98px) {
  .footer {
    .get_in_touch {
      padding: 15px;
      gap: 12px;
      h1 {
        text-align: center;
        font-size: 48px;
      }
      .arrowImg {
        max-width: 60px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .footer {
    .get_in_touch {
      padding: 15px;
      h1 {
        text-align: center;
        font-size: 36px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .footer {
    padding: 50px 0 20px;
    .get_in_touch {
      margin: 0 0 10px 0;
      gap: 10px;
      h1 {
        text-align: center;
        font-size: 28px;
      }
      .arrowImg {
        max-width: 30px;
      }
    }
    .footer_list {
      img {
        max-width: 250px;
      }
      h4 {
        margin-bottom: 20px;
      }
      ul {
        li {
          margin-bottom: 10px;
        }
      }
    }
    .copyright_sec {
      text-align: center;
    }
    .clock {
      margin-top: 15px;
      gap: 8px;
    }
  }
}
