@import "./variables.scss";

.spacer {
  padding: 180px 0;
}

.tags_links {
  .tag {
    height: 37px;
    display: flex;
    align-items: center;
    padding: 0 19px;
    background-color: #2e2e2e;
    color: #959595;
    font-size: 14px;
    font-weight: 400;
  }
  .link {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2e2e2e;
    border-radius: 100%;
  }
}

.arrow {
  cursor: pointer;
  .arrow_txt {
    color: $text-blue;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.96px;
  }
}

.related_projects {
  margin: 150px auto 80px;
  h4 {
    font-family: "Arimo", sans-serif;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 60px;
  }
}

.project {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(9, 9, 9, 0) 50.26%,
      #090909 99.06%
    );
  }
  &:hover {
    cursor: pointer;
    .arrow {
      bottom: 8%;
    }
  }
  .arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30%;
    transition: bottom ease 500ms;
  }
}

.comments {
  margin-bottom: 80px;
  h4 {
    font-family: "Arimo", sans-serif;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 60px;
  }
  div {
    row-gap: 40px;
  }
}

@media (max-width: 1199.98px) {
  .spacer {
    padding: 150px 0;
  }
}

@media (max-width: 991.98px) {
  .spacer {
    padding: 130px 0;
  }

  .comments {
    margin-bottom: 70px;
    h4 {
      font-size: 30px;
      margin-bottom: 45px;
    }
    div {
      row-gap: 30px;
    }
  }

  .related_projects {
    margin: 130px auto 80px;
    h4 {
      margin-bottom: 50px;
      font-size: 30px;
    }
  }
}

@media (max-width: 767.98px) {
  .spacer {
    padding: 100px 0;
  }

  .related_projects {
    margin: 100px auto 80px;
    h4 {
      margin-bottom: 40px;
      font-size: 30px;
    }
  }
}

@media (max-width: 575.98px) {
  .spacer {
    padding: 80px 0;
  }

  .tags_links {
    .tag {
      height: 30px;
      padding: 0 12px;
      font-size: 12px;
    }
    .link {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2e2e2e;
      border-radius: 100%;
    }
  }

  .comments {
    margin-bottom: 60px;
    h4 {
      font-size: 26px;
      margin-bottom: 30px;
    }
    div {
      row-gap: 20px;
    }
  }

  .related_projects {
    margin: 80px auto;
    h4 {
      margin-bottom: 30px;
      font-size: 26px;
    }
  }
}
