@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./variables.scss";

// font-family: 'Rubik', sans-serif;
// font-family: 'Arimo', sans-serif;

* {
  margin: 0;
  padding: 0;
  color: $white-color;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  letter-spacing: 1.2px;
  color: $white-color;
}

::-webkit-scrollbar {
  width: 5px;
  background: $background-color-2;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background: $gradient-background;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #362828;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $placeholder-color;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.background {
  position: relative;
  z-index: 1;
  .lines {
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: $background-line-color;
    z-index: -1;
    &::before {
      content: "";
      width: 1px;
      right: -1px;
      height: 60px;
      border-radius: 100%;
      position: absolute;
      background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(#ffffff),
        to(transparent)
      );
      background-image: linear-gradient(0deg, #ffffff, transparent);
    }
    &:nth-child(even):before {
      -webkit-animation: line_animation 20s ease-out infinite;
      animation: line_animation 20s ease-out infinite;
    }
    &:nth-child(odd):before {
      -webkit-animation: line_animation_2 20s ease-out infinite;
      animation: line_animation_2 20s ease-out infinite;
    }
    &:nth-child(1) {
      left: 12.5%;
    }
    &:nth-child(2) {
      left: 25%;
    }
    &:nth-child(3) {
      left: 37.5%;
    }
    &:nth-child(4) {
      left: 50%;
    }
    &:nth-child(5) {
      left: 62.5%;
    }
    &:nth-child(6) {
      left: 75%;
    }
    &:nth-child(7) {
      left: 87.5%;
    }
  }
}

.maps {
  filter: grayscale(00%) invert(100%) contrast(100%);
  iframe {
    width: 100%;
    height: 750px;
  }
}

@-webkit-keyframes line_animation {
  0% {
    top: 0px;
    opacity: 1;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 100%;
    opacity: 1;
  }
}
@keyframes line_animation {
  0% {
    top: 0px;
    opacity: 1;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 100%;
    opacity: 1;
  }
}

@-webkit-keyframes line_animation_2 {
  0% {
    opacity: 1;
    bottom: 0px;
  }
  50% {
    bottom: 50%;
  }
  100% {
    bottom: 100%;
    opacity: 1;
  }
}
@keyframes line_animation_2 {
  0% {
    opacity: 1;
    bottom: 0px;
  }
  50% {
    bottom: 50%;
  }
  100% {
    bottom: 100%;
    opacity: 1;
  }
}
.notfound {
  min-height: 100vh;
  h1 {
    font-size: 160px;
    background: $gradient-background;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: transparent;
  }
}
.heading {
  h2 {
    color: $text-blue;
    text-align: center;
    font-size: 28px;
    font-family: "Arimo", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }
}
.company_list {
  .company_brands {
    display: inline-block;
    width: 100%;
    max-width: 200px;
    text-align: center;
    &:hover {
      cursor: pointer;
      img {
        opacity: 1;
      }
    }
    img {
      opacity: 0.5;
      width: 100%;
      transition: 200ms ease-in;
    }
  }
  .heading {
    h2 {
      margin-bottom: 60px;
    }
  }
}

.team {
  padding: 160px 0;
  .team_box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    cursor: pointer;
    &:hover {
      .team_profile {
        figure {
          &::before {
            top: -100%;
          }
          &::after {
            bottom: -100%;
            transition-delay: 0.5;
          }
        }
      }
    }
  }
  .team_profile {
    text-align: center;
    figure {
      width: 100%;
      height: auto;
      position: relative;
      overflow: hidden;
      border-radius: 30px;
      img {
        border-radius: 30px;
        width: 100%;
        height: auto;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: $placeholder-color;
        opacity: 0.3;
        z-index: 1;
        transition: 0.5s;
        border-radius: 30px 0 0 30px;
      }
      &:after {
        content: "";
        opacity: 0.3;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background: $placeholder-color;
        z-index: 1;
        transition: 0.5s;
      }
    }
  }
  .team_info {
    h4 {
      font-size: 36px;
      font-weight: 600;
    }
  }
}

.spacer {
  padding-bottom: 180px;
  .team_details_main {
    padding: 100px 0 80px;
    .team_profile {
      width: 100%;
      max-width: 500px;
      img {
        width: 100%;
      }
    }
  }
  .team_details_row {
    max-width: 800px;
    margin: 0 auto;
  }
  .team_details {
    padding-left: 48px;
    p {
      margin-bottom: 20px;
    }
    b {
      text-transform: uppercase;
    }
    span {
      color: #bac1d4;
    }
    .team_social_icons {
      margin-top: 30px;
      padding: 0;
      p {
        font-weight: 700;
      }
      li {
        background-color: #202020;
        height: 60px;
        width: 60px;
        border-radius: 100%;
        margin-right: 15px;
        display: inline-block;
      }
      a {
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        svg {
          transition: all 200ms ease;
        }
        &:hover {
          svg {
            fill: #006eda;
          }
        }
      }
    }
  }
  .team_member_allInfo {
    margin-bottom: 80px;
    h4 {
      font-size: 30px;
      font-family: "Arimo", sans-serif;
      font-weight: 700;
      margin-bottom: 36px;
    }
    p {
      color: #bac1d4;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
    }
    .team_member_skills {
      display: flex;
      gap: 30px;
      text-align: center;
      .chartMain {
        position: relative;
        width: 100%;
        margin: 0 auto;
        .percent {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 40px;
          font-weight: 800;
        }
      }
      p {
        height: 100%;
        margin-top: 15px;
        margin-bottom: 0;
        text-align: center;
        font-size: 16px;
        font-family: "Arimo", sans-serif;
        letter-spacing: 1.4px;
        text-transform: uppercase;
      }
    }
  }
}

.contact_form {
  background-color: #1c1c1c;
  padding: 30px;
  margin: 0 50px;
  border-radius: 20px;
  .form_heading {
    margin-bottom: 30px;
    font-family: "Arimo", sans-serif;
    p {
      color: #bac1d4;
      font-size: 14px;
    }
  }
}

.form_control {
  margin-bottom: 20px;
  label {
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 20px;
  }
  input,
  textarea {
    width: 100%;
    color: #bac1d4;
    font-size: 16px;
    padding: 15px;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #393939;
    &:focus-visible {
      outline: solid 1px #0b68d1;
    }
    ::placeholder {
      color: $placeholder-color;
    }
  }
}
.submit_btn {
  margin: 20px 0;
  button {
    background: $gradient-background;
    padding: 15px 40px;
    border: 0;
    color: $white-color;
    letter-spacing: 1px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-transform: uppercase;
  }
}

.portfolio {
  padding: 100px 0;
  .portfolio_card {
    img {
      border-radius: 20px;
      width: 100%;
    }
    .portfolio_card_box {
      position: relative;
      z-index: 7;
      cursor: pointer;
      &:hover {
        &::before {
          display: block;
        }
      }
      &::before {
        display: none;
        content: "";
        position: absolute;
        z-index: 8;
        height: 100%;
        width: 100%;
        background: url(https://mysocialtheory.com/assets/images/arrow_up.png)
          no-repeat rgb(0, 0, 00, 0.2);
        background-size: unset;
        inset: 0%;
        background-position: 50% 80%;
      }
    }
  }
}

.portfolio_tabbing {
  width: 100vw;
  border: 0;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 90px;
  .nav-item {
    background: transparent;
    font-size: 18px;
    .nav-link.active {
      border-radius: 50px;
      border: 0;
      color: $white-color;
      background: $gradient-background;
    }
    .nav-link {
      color: $white-color;
      letter-spacing: 2.1px;
      text-transform: capitalize;
      border-radius: 50px;
      font-weight: 700;
      border: 1px solid rgba(255, 255, 255, 0.2);
      &:hover {
        border-radius: 50px;
      }
    }
  }
}

.contactus_form_section {
  padding: 100px 0;
  .contactUs_form {
    padding-left: 80px;
  }
  .contactus_box {
    h3 {
      font-family: "Arimo", sans-serif;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 25px;
    }
    span {
      color: #bac1d4;

      font-size: 18px;
      line-height: 30px;
    }
  }
  .contactus_heading {
    margin-bottom: 35px;
  }
  .contactus_box_info {
    ul {
      padding: 0;
      li {
        margin-bottom: 20px;
        list-style: none;
      }
      a {
        color: #bac1d4;
      }
    }
    .company_socical_ico {
      background: $gradient-background;
      font-size: 18px;
      height: 50px;
      width: 50px;
      padding: 15px;
      border-radius: 100%;
      margin-right: 20px;
    }
  }
}

.portfolio_details {
  .portfolio_banner {
    margin-bottom: 150px;
    img {
      width: 100%;
      border-radius: 20px;
    }
  }
  .portfolio_box_info {
    margin-bottom: 50px;
    h4 {
      text-transform: capitalize;
      font-family: "Arimo", sans-serif;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 35px;
    }
    p {
      color: #bac1d4;
      font-size: 18px;
      line-height: 30px;
    }
    .check_icon {
      margin-right: 20px;
    }
    .portfolio_solution_list {
      p {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        position: relative;
        &::before {
          content: "";
          height: 15px;
          width: 15px;
          background: $gradient-background;
          position: absolute;
          top: calc(50% + 3px);
          transform: translateY(-50%);
          left: 0;
          z-index: -1;
        }
      }
    }
  }
  .information_box {
    background-color: #171717;
    border-radius: 20px;
    padding: 45px;
    h4 {
      color: #fff;
      font-family: "Arimo", sans-serif;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 36px;
    }
    p {
      white-space: nowrap;
    }
  }
}

.member_review {
  font-family: "Rubik", sans-serif;
  padding-top: 100px;
  h5 {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.68px;
    text-transform: uppercase;
  }
  p {
    font-size: 30px;
    line-height: 43px;
    max-width: 75%;
    margin: 50px auto;
  }
  h6 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    letter-spacing: 3.24px;
    text-transform: uppercase;
  }
  span {
    color: $text-blue;
    font-size: 16px;
    letter-spacing: 2.16px;
    text-transform: uppercase;
  }
  .member_review_slider {
    position: relative;
    img {
      top: 0;
      right: 0;
      cursor: pointer;
    }
    .swiper_prev {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(50%);
      z-index: 9;
    }
    .swiper_next {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(50%);
      z-index: 99;
    }
  }
}

.latest_posts {
  padding-bottom: 100px;
  .latestpost_heading {
    margin-bottom: 90px;
    h2 {
      font-family: "Arimo", sans-serif;
      font-size: 50px;
      font-weight: 700;
      line-height: 60px;
      text-transform: uppercase;
    }
    span {
      background: $gradient-background;
      -webkit-text-stroke: 4px transparent;
      -webkit-background-clip: text;
      color: $background-color;
    }
  }
  .latest_posts_box {
    img {
      width: 100%;
      border-radius: 20px;
    }
    h4 {
      height: 58px;
      font-family: "Arimo", sans-serif;
      font-size: 24px;
      font-weight: 700;
      margin: 20px 0;
      overflow: hidden;
    }
    a {
      display: flex;
      align-items: center;
      column-gap: 10px;
      color: $text-blue !important;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.5px;
      letter-spacing: 2.1px;
      text-transform: uppercase;
      text-decoration: none;
      img {
        max-width: 14px;
      }
    }
  }
}

// about us

.aboutus_heading {
  position: absolute;
  top: 5%;
  right: -20%;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 128px;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  &::after {
    content: "";
  }
}

.ourstory_heading {
  right: 0;
  left: -50%;
}

.aboutus_left_para {
  max-width: 680px;
  margin: 50px 0 0 50px;
  p {
    font-size: 18px;
    line-height: 1.6;
  }
}

.aboutus_right_para {
  p {
    max-width: 525px;
    font-size: 48px;
    letter-spacing: 1px;
    line-height: 1.3;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -44px;
      width: 100%;
      max-width: 200px;
      height: 8px;
      background-color: $text-blue;
    }
  }
}

.aboutus_img {
  max-width: 730px;
}

//hero-slider

.slider_main {
  margin: 120px 0;
}

.slide_number {
  max-width: 80px;
  top: 10%;
  left: 3%;
  span {
    font-size: 28px;
    font-family: "Arimo", sans-serif;
    font-weight: 500;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.next_slide {
  width: 100%;
  max-width: 45%;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  .headings {
    h6 {
      font-size: 120px;
      text-align: end;
      letter-spacing: 24px;
      font-family: "Arimo", sans-serif;
    }
    div {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 300px;
      font-family: "Arimo", sans-serif;
      column-gap: 20px;
      p {
        font-size: 30px;
        white-space: nowrap;
      }
      span {
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: white;
      }
    }
  }
}

.backimg {
  min-height: 250px;
}

.compnay_slider {
  padding-bottom: 120px;
}

.marqueeslider_main {
  padding: 60px 0;
}

// clock

.react-clock__face {
  border: 6px solid $text-blue;

  .react-clock__minute-mark {
    &:nth-child(6),
    &:nth-child(18),
    &:nth-child(31),
    &:nth-child(42) {
      .react-clock__minute-mark__body {
        width: 6px !important;
        height: 6px;
        margin-top: 2px;
        border-radius: 100%;
        background-color: $text-blue;
      }
    }
    .react-clock__minute-mark__body {
      background-color: transparent;
    }
  }
  .react-clock__hour-mark {
    .react-clock__mark__body {
      width: 0;
      height: 0;
      font-size: 12px;
    }
    &:nth-child(51),
    &:nth-child(54),
    &:nth-child(57),
    &:nth-child(60) {
      .react-clock__mark__body {
        transform-origin: center;
        &::before {
          position: absolute;
          top: 50%;
          bottom: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    &:nth-child(57) {
      .react-clock__mark__body {
        &::before {
          content: "9";
        }
      }
    }
    &:nth-child(54) {
      .react-clock__mark__body {
        &::before {
          content: "6";
        }
      }
    }
    &:nth-child(51) {
      .react-clock__mark__body {
        &::before {
          content: "3";
        }
      }
    }
    &:last-child {
      .react-clock__mark__body {
        &::before {
          content: "12";
        }
      }
    }
  }
}

.react-clock__hand__body {
  width: 3px !important;
  background-color: #da002f !important;
  border-radius: 5px;
}

// Homepage hero slider

.slide_main {
  overflow: hidden;
  .slide_left {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    max-width: 65.4%;
    transition: all 600ms ease;
    &:hover {
      scale: 1.02;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .slide_right {
    display: block;
    width: 100%;
    max-width: 62.7%;
    margin-left: auto;
    text-align: end;
    position: relative;
    z-index: 2;
    transition: all 600ms ease;
    clip-path: polygon(40% 0, 100% 0, 100% 100%, 0% 100%);
    &:hover {
      scale: 1.02;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.slide1 {
  position: relative;
}
.slide2 {
  position: relative;
  .slide_left {
    max-width: 64.4%;
  }
}

.slide3 {
  position: relative;
}

.services {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 118px);
  min-height: calc(100vh - 118px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: $gradient-background;
  .wht_we_do,
  .how_we_do {
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .number {
      position: absolute;
      top: 5%;
      max-width: 60px;
      img {
        width: 100%;
        height: auto;
      }
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .text {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      h1 {
        font-size: 80px;
        margin-bottom: 10px;
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: block;
          &:nth-child(1),
          &:nth-child(3) {
            width: 100%;
            margin: 0 10%;
            height: 1px;
            background-color: $text-blue;
          }
          &:nth-child(2) {
            text-align: center;
            font-size: 14px;
            letter-spacing: 2px;
            word-spacing: 2px;
            white-space: nowrap;
          }
        }
        .red {
          background-color: #db0030 !important;
        }
      }
      p {
        margin: 10px 0 0;
        font-size: 18px;
      }
    }
  }
}

.services_home {
  height: max-content !important;
}

.wht_we_do {
  .number {
    left: 2%;
  }
}

.how_we_do {
  .number {
    right: 2%;
  }
}

@media (max-width: 1399.98px) {
  .team {
    padding: 120px 0;
  }

  .aboutus_heading {
    font-size: 100px;
  }
  .aboutus_right_para {
    p {
      max-width: 450px;
      font-size: 38px;
    }
  }
  .slider_main {
    margin: 100px 0;
  }
  .next_slide {
    .headings {
      h6 {
        font-size: 100px;
        letter-spacing: 20px;
      }
    }
  }
  .slide_number {
    max-width: 70px;
  }
  .compnay_slider {
    padding-bottom: 140px;
  }
  .marqueeslider_main {
    padding: 80px 0;
  }
  .spacer {
    padding-bottom: 140px;
  }
}

@media (max-width: 1199.98px) {
  .aboutus_heading {
    font-size: 80px;
  }
  .aboutus_right_para {
    p {
      max-width: 350px;
      font-size: 28px;
    }
  }
  .slider_main {
    margin: 100px 0;
  }
  .next_slide {
    .headings {
      h6 {
        font-size: 80px;
        letter-spacing: 14px;
      }
      div {
        max-width: 200px;
        column-gap: 10px;
        p {
          font-size: 24px;
        }
      }
    }
  }
  .compnay_slider {
    padding-bottom: 100px;
  }
  .marqueeslider_main {
    padding: 60px 0;
  }
  .member_review {
    padding-top: 120px;
    p {
      font-size: 24px;
      line-height: 1.4;
    }
  }
  .spacer {
    padding-bottom: 100px;
  }
}

//services

.swiper-button-disabled {
  opacity: 0.5;
}

// .service_swiper {
//   .swiper-slide {
//     height: auto;
//     display: flex;
//     align-items: center;
//   }
// }

@media (max-width: 991.98px) {
  .team_info {
    h4 {
      font-size: 30px;
    }
  }

  .team {
    padding: 80px 0;
  }

  .notfound {
    h1 {
      font-size: 72px;
    }
  }
  .contactus_form_section {
    .contactus_box {
      margin-bottom: 30px;
    }
    .contactUs_form {
      padding: 0;
    }
  }

  .aboutus_heading {
    font-size: 64px;
  }
  .aboutus_right_para {
    p {
      max-width: 300px;
      font-size: 26px;
    }
  }
  .company_list {
    .heading {
      h2 {
        margin-bottom: 50px;
      }
    }
  }
  .slider_main {
    margin: 80px 0;
  }
  .next_slide {
    .headings {
      h6 {
        font-size: 60px;
        letter-spacing: 8px;
      }
    }
  }
  .slide_number {
    max-width: 60px;
  }
  .compnay_slider {
    padding-bottom: 80px;
  }
  .member_review {
    padding-top: 100px;
  }
  .spacer {
    padding-bottom: 80px;
  }

  .portfolio_details {
    .portfolio_banner {
      margin-bottom: 120px;
    }
  }

  .team {
    .team_profile {
      figure {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .team_info {
    h4 {
      font-size: 26px;
      margin-top: 10px;
    }
  }

  .team {
    padding: 60px 0;
  }

  .company_list {
    .company_brands {
      max-width: 160px;
    }
  }

  .services {
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 94.75px);
    min-height: calc(100vh - 94.75px);
  }

  .services .wht_we_do .text h1,
  .services .how_we_do .text h1 {
    font-size: 70px;
  }
  .notfound {
    h1 {
      font-size: 45px;
    }
  }

  .aboutus_heading {
    position: static;
    font-size: 54px;
  }

  .aboutus_right_para {
    p {
      max-width: 85%;
      margin: 20px auto;
      font-size: 26px;
      text-align: center;
      &::before {
        content: none;
      }
    }
  }
  .aboutus_img {
    border-radius: 20px;
  }

  .aboutus_left_para {
    max-width: 100%;
    margin: 20px 0 0;
  }
  .company_list {
    .heading {
      h2 {
        margin-bottom: 40px;
      }
    }
  }
  .slider_main {
    margin: 60px 0;
  }
  .next_slide {
    .headings {
      h6 {
        font-size: 40px;
        letter-spacing: 4px;
      }
      div {
        max-width: 150px;
        column-gap: 5px;
        p {
          font-size: 20px;
        }
      }
    }
  }
  .compnay_slider {
    padding-bottom: 60px;
  }
  .marqueeslider_main {
    padding: 40px 0;
  }
  .member_review {
    padding-top: 80px;
  }
  .spacer {
    padding-bottom: 60px;
  }

  .latest_posts .latest_posts_box {
    h4 {
      height: auto;
    }
  }

  .portfolio_details {
    .portfolio_banner {
      margin-bottom: 100px;
    }
  }

  .spacer {
    .team_member_allInfo {
      .team_member_skills {
        .chartMain {
          width: 100%;
          max-width: 200px;
          .percent {
            font-size: 34px;
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .team {
    padding: 40px 0;
  }

  .company_list {
    .company_brands {
      max-width: 120px;
    }
  }

  .services .wht_we_do .text p,
  .services .how_we_do .text p {
    font-size: 14px;
  }

  .how_we_do .number {
    left: 2%;
  }

  .spacer {
    .team_details_main {
      padding: 25px 0 25px;
      .team_profile {
        margin-bottom: 15px;
      }
      .team_details {
        padding: 0;
        text-align: center;
      }
      .team_member_allInfo {
        margin-bottom: 25px;
        h4 {
          margin-bottom: 15px;
          font-size: 18px;
        }
        p {
          font-size: 16px;
        }
        .team_member_skills {
          justify-content: center;
          img {
            width: 100%;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  .team {
    padding: 50px 30px;
    .team_info {
      h4 {
        font-size: 20px;
      }
    }
  }
  .contact_form {
    padding: 30px 15px;
    margin: 15px;
  }
  .form_control {
    label {
      margin-bottom: 10px;
    }
    input {
      padding: 10px;
    }
  }
  .submit_btn {
    margin: 0;
    button {
      width: 100%;
    }
  }
  .portfolio {
    padding: 40px 15px;
  }

  .contactus_form_section {
    padding: 30px 15px;
    .contactus_heading {
      h3 {
        font-size: 24px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
      }
    }
    .contactus_box_info {
      h6 {
        font-size: 16px;
      }
      span {
        font-size: 14px;
      }
    }
    .contactus_box {
      margin-bottom: 30px;
    }
  }
  .latest_posts {
    padding-bottom: 50px;
    .latestpost_heading {
      margin-bottom: 30px;
      h2 {
        font-size: 36px;
      }
    }
    .latest_posts_box {
      h4 {
        font-size: 18px;
        margin: 10px 0;
      }
    }
  }
  .aboutus_heading {
    font-size: 34px;
  }

  .aboutus_right_para {
    p {
      max-width: 100%;
      margin: 15px auto;
      font-size: 20px;
      text-align: center;
    }
  }
  .aboutus_left_para {
    p {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  .company_list {
    .heading {
      h2 {
        margin-bottom: 30px;
      }
    }
  }
  .slider_main {
    margin: 40px 0;
  }
  .slide_number {
    max-width: 50px;
  }
  .compnay_slider {
    padding-bottom: 40px;
  }
  .marqueeslider_main {
    padding: 20px 0;
  }
  .member_review {
    padding-top: 50px;

    .member_review_slider {
      .swiper_prev {
        display: inline-block;
        position: static;
        margin-right: 10px;
      }
      .swiper_next {
        display: inline-block;
        position: static;
        margin-left: 10px;
      }
    }
    p {
      max-width: 100%;
    }
  }

  .portfolio_details {
    .portfolio_banner {
      margin-bottom: 80px;
    }
  }
  .member_review {
    p {
      margin: 30px 0;
      font-size: 16px;
      line-height: 1.4;
    }
    h6,
    span {
      font-size: 14px;
    }
  }
  .services {
    height: 100%;
    min-height: calc(100vh - 90px);
    max-height: 100%;
    .wht_we_do,
    .how_we_do {
      .number {
        // max-width: 50px;
        display: none;
      }
      .text {
        h1 {
          font-size: 60px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .team_info {
    h4 {
      font-size: 24px;
      margin-top: 6px;
    }
  }

  .services .wht_we_do .text h1,
  .services .how_we_do .text h1 {
    font-size: 50px;
  }

  .aboutus_heading {
    font-size: 26px;
  }

  .team_details_main {
    .team_member_allInfo {
      .team_member_skills {
        flex-direction: column;
        .chartMain {
          width: 100%;
          max-width: 150px;
          margin: 0 auto;
        }
      }
    }
  }

  .spacer {
    .team_member_allInfo {
      .team_member_skills {
        .chartMain {
          .percent {
            font-size: 26px;
          }
        }
      }
    }
  }
}

.offcanvas-backdrop {
  z-index: 90 !important;
}

.main_left {
  > div {
    padding-left: 100px;
  }
  width: 60%;
  height: 100%;
  padding: 40px 0;
  background: linear-gradient(
    137.14deg,
    #006eda 37.35%,
    #723582 60.45%,
    #db0030 86.59%
  );
  clip-path: polygon(73.5% 100%, 93.5% 0, 0 0, 0 100%);
}

.main_right {
  width: 60%;
  height: 100%;
  padding: 40px 0;
  background: linear-gradient(
      137.14deg,
      #006eda 37.35%,
      #723582 60.45%,
      #db0030 86.59%
    ),
    linear-gradient(0deg, #006eda, #006eda);

  clip-path: polygon(26.5% 0, 100% 0, 100% 100%, 6.5% 100%);
}

.number_main {
  width: 100%;
  max-width: 100px;
  position: relative;
  .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 28px;
  }
}

.content_main {
  width: 100%;
  max-width: 500px;
  height: max-content;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  .B {
    position: absolute;
    background-color: $white-color;
  }
  .arrow_main {
    width: 100%;
    max-width: 70px;
    margin-left: auto;
  }
  .heading {
    font-size: 120px;
    margin-left: -80px;
    white-space: nowrap;
    span {
      font-size: 48px;
    }
  }
}

.text_head {
  width: 100%;
  max-width: 85%;
  margin: 0 auto 20px;
  span {
    &:first-child,
    &:last-child {
      display: inline-block;
      width: 100%;
      height: 2px;
      background-color: $white-color;
    }
    &:nth-child(2) {
      white-space: nowrap;
      font-size: 30px;
    }
  }
}

.texts {
  max-width: 85%;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
}

.border_lefttop {
  width: 15px;
  left: 0;
  bottom: 80%;
  height: 0px;
}

.border_top {
  left: 0;
  top: 0;
  width: 0%;
  height: 15px;
}

.border_righttop {
  top: 0;
  right: 0;
  width: 15px;
  height: 0%;
}

.border_rightbottom {
  bottom: 0;
  right: 0;
  width: 15px;
  height: 0%;
}

.border_bottom {
  height: 15px;
  width: 0%;
  left: 0;
  bottom: 0;
}

.border_leftbottom {
  width: 15px;
  height: 0%;
  top: 40%;
  left: 0;
}
