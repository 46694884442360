@import "../../assets/css/variables.scss";

.nav_main {
  background-color: transparent;
  transition: 300ms ease-in-out;
  div {
    &:first-child {
      a {
        img {
          max-width: 250px;
        }
      }
    }
  }
}

.navbar_btn {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  border: none;
  background: $gradient-background;
  transition: 500ms ease-in-out;
  &:hover {
    rotate: 0.5turn;
    scale: 0.9;
    box-shadow: 0 0 1px 15px rgba(#8a3b58, 0.4), 0 0 1px 30px rgba(#8a3b58, 0.1),
      0 0 1px 45px rgba(#8a3b58, 0.1);
  }
}

.nav_link {
  position: relative;
  color: $white-color;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: transparent;
  transition: 0.5s ease-out;
  font-weight: 700;
  background-size: 220% 100%;
  background-position: 100% 50%;
  &:not(.nav_link_active) {
    -webkit-background-clip: text;
    background-clip: text;
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, $text-blue 45%, #fff 55%);
  }
  &:hover {
    &:not(.nav_link_active) {
      background-position: 0% 50%;
    }
  }
}

.nav_link_active {
  color: $text-blue;
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -15px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: $gradient-background;
  }
}

.offcanvas_main {
  z-index: 91 !important;
  width: 100% !important;
  background-color: $background-color !important;
  .canvas_header_main {
    .offcanvas_logo {
      display: inline-block;
      width: 100%;
      max-width: 300px;
      img {
        width: 100%;
        height: auto;
      }
    }
    div {
      &:last-child {
        background-color: $background-line-color;
      }
    }
  }
  .row_main {
    height: 100%;
    overflow-y: auto;
    .offcanvas_left {
      .offcanvas_nav_main {
        text-transform: uppercase;
        ul {
          list-style: none;
          padding: 0;
          .offcanvas_navlink_active {
            div {
              &:first-child {
                background: linear-gradient(120deg, #006eda, #723582, #db0030);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: transparent;
                font-family: "Arimo", sans-serif;
              }
              &:last-child {
                background: linear-gradient(120deg, #006eda, #723582, #db0030);
              }
            }
          }
          li {
            a {
              display: flex;
              justify-content: space-between;
              padding-left: 48px;
              font-size: 48px;
              font-weight: 700;
              border-top: 1px solid $background-line-color;
              div {
                &:first-child {
                  padding: 24px 0;
                  font-family: "Arimo", sans-serif;
                }
                &:last-child {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  max-width: 84px;
                  background: $background-color-2;
                  cursor: pointer;
                  img {
                    max-width: 31px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .offcanvas_right {
      background-color: $background-line-color;
      padding: 15px 40px;
      .search_main {
        position: relative;
        input {
          &:focus {
            outline: none;
          }
          padding: 18px 60px 18px 21px;
          border: 1px solid $border-color-3;
          background-color: transparent;
          border-radius: 50px;
        }
        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 36px;
        }
      }
      .contactus_box_info {
        .contactus_heading {
          margin: 80px 0 35px;
          display: flex;
          align-items: center;
          column-gap: 15px;
          h3 {
            margin: 0;
            font-size: 28px;
            font-weight: 700;
            font-family: "Arimo", sans-serif;
          }
          img {
            max-width: 40px;
          }
        }
        ul {
          padding: 0;
          li {
            list-style: none;
            &:not(:last-child) {
              margin-bottom: 30px;
            }
            div {
              h6 {
                font-size: 20px;
                font-family: "Arimo", sans-serif;
              }
              span {
                word-break: break-all;
                font-size: 18px;
                color: $text-color;
              }
              .company_socical_ico {
                background: $gradient-background;
                height: 50px;
                width: 50px;
                padding: 13px;
                border-radius: 100%;
                margin-right: 20px;
              }
            }
          }
        }
      }
      .followus_main {
        margin-top: 110px;
        h3 {
          margin: 0;
          font-size: 28px;
          font-weight: 700;
          font-family: "Arimo", sans-serif;
        }
        .icons {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          margin-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .offcanvas_main {
    .canvas_header_main {
      div {
        &:last-child {
          background-color: transparent;
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .nav_main {
    div {
      &:first-child {
        a {
          img {
            max-width: 230px;
          }
        }
      }
    }
  }

  .offcanvas_main {
    .row_main {
      .offcanvas_right {
        padding: 20px;
        .contactus_box_info {
          .contactus_heading {
            margin: 40px 0 25px;
          }
        }
        .followus_main {
          margin-top: 80px;
        }
      }
      .offcanvas_left {
        .navbar_main {
          ul {
            li {
              div {
                &:first-child {
                  font-size: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .nav_main {
    padding: 20px !important;
  }

  .navbar_btn {
    width: 50px;
    height: 50px;
    img {
      max-width: 20px;
    }
  }

  .offcanvas_main {
    overflow-y: scroll;
    .row_main {
      .offcanvas_left {
        .offcanvas_nav_main {
          ul {
            li {
              div {
                &:first-child {
                  font-size: 30px;
                  padding: 14px 0;
                }
                &:last-child {
                  max-width: 60px;
                }
              }
            }
          }
        }
      }
      .offcanvas_right {
        padding: 15px 40px;
        .followus_main {
          margin-top: 40px;
          h3 {
            font-size: 24px;
          }
          .icons {
            div {
              max-width: 35px;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
    .canvas_header_main {
      padding: 20px 40px;
      .offcanvas_logo {
        max-width: 200px;
      }
      div {
        &:last-child {
          background-color: transparent;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .nav_main {
    div {
      &:first-child {
        a {
          img {
            max-width: 160px;
          }
        }
      }
    }
  }

  .offcanvas_main {
    .row_main {
      .offcanvas_left {
        .offcanvas_nav_main {
          ul {
            li {
              a {
                padding-left: 20px;
              }
              div {
                &:first-child {
                  font-size: 24px;
                  padding: 10px 0;
                }
                &:last-child {
                  max-width: 50px;
                }
              }
            }
          }
        }
      }
      .offcanvas_right {
        padding: 15px 30px;
        .contactus_box_info {
          ul {
            li {
              div {
                h6 {
                  font-size: 16px;
                  margin-bottom: 4px;
                }
                span {
                  font-size: 14px;
                }
                .company_socical_ico {
                  width: 30px;
                  height: 30px;
                  padding: 6px;
                }
              }
            }
          }
          .contactus_heading {
            margin: 30px 0 20px;
            h3 {
              font-size: 24px;
            }
          }
        }
        .search_main {
          input {
            padding: 10px 60px 10px 21px;
          }
        }
      }
    }
    .canvas_header_main {
      padding: 20px;
      .offcanvas_logo {
        max-width: 160px;
      }
    }
  }
}
