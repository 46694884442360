@import "../../assets//css//variables.scss";

.project_main {
  position: relative;
  padding: 80px 48px;
}

.left_main {
  h3 {
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Arimo", sans-serif;
    span {
      font-family: "Arimo", sans-serif;
      background: $gradient-background;
      -webkit-background-clip: text;
      -webkit-text-stroke: 4px transparent;
      color: $background-color-3;
      letter-spacing: 0.5px;
    }
  }
}

.tabs {
  margin-top: 70px;
  ul {
    list-style: none;
    row-gap: 44px;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      span {
        &::after {
          content: "";
        }
        &:first-child {
          font-size: 24px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 2.52px;
          white-space: nowrap;
        }
      }
      .line {
        display: inline-block;
        width: 100%;
        height: 1px;
        background-color: transparent;
        margin-right: auto;
      }
    }
    .active_tab {
      span {
        &:first-child {
          color: $text-blue;
        }
      }
      .line {
        background-color: #3e4faa;
      }
    }
  }
}

.tab1_main {
  div {
    margin-left: 40px;
    video {
      border-radius: 20px;
    }
  }
}

@media (max-width: 1199.98px) {
  .left_main {
    h3 {
      font-size: 44px;
    }
  }

  .project_main {
    padding: 100px 30px;
  }

  .tabs {
    margin-top: 50px;
  }
}

@media (max-width: 991.98px) {
  .project_main {
    padding: 80px 30px;
  }

  .tab1_main {
    margin-top: 40px;
    div {
      margin: 0 auto;
    }
  }

  .tabs {
    max-width: 600px;
    margin: 40px auto 0;
    ul {
      row-gap: 33px;
      li {
        span {
          &:first-child {
            font-size: 20px;
            letter-spacing: 2px;
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .left_main {
    h3 {
      font-size: 38px;
    }
  }

  .project_main {
    padding: 60px 20px;
  }

  .tab1_main {
    div {
      max-width: 100%;
    }
  }
}

@media (max-width: 575.98px) {
  .project_main {
    padding: 40px 20px;
  }

  .tab1_main {
    margin-top: 20px;
  }

  .tabs {
    margin: 20px auto 0;
    ul {
      row-gap: 22px;
      li {
        span {
          &:first-child {
            font-size: 18px;
            letter-spacing: 1.52px;
          }
        }
      }
    }
  }
}
