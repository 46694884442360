@import "../../assets/css/variables.scss";

.marquee_slider {
  padding: 70px 0;
  border: 1px solid $background-line-color;
  border-left: 0;
  border-right: 0;
  .initial-child-container {
    align-items: center;
  }
  .brand_name {
    font-family: "Arimo", sans-serif;
    font-size: 80px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 20px;
  }
}

@media (max-width: 991.98px) {
  .marquee_slider {
    padding: 60px 0;
    .brand_name {
      font-size: 60px;
    }
    img{
        max-width: 60px;
    }
  }
}

@media (max-width: 767.98px) {
  .marquee_slider {
    padding: 50px 0;
    .brand_name {
      font-size: 48px;
    }
    img{
        max-width: 50px;
    }
  }
}

@media (max-width: 575.98px) {
  .marquee_slider {
    padding: 40px 0;
    h2 {
      font-size: 36px;
    }
  }
}
