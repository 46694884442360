@import "../../assets//css/variables.scss";

.professionals_main {
  padding: 80px 48px;
  background: $gradient-background;
}

.left_main {
  h3 {
    margin-bottom: 40px;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 700;
    font-family: "Arimo", sans-serif;
    white-space: nowrap;
    span {
      font-family: "Arimo", sans-serif;
      background: white;
      -webkit-background-clip: text;
      -webkit-text-stroke: 4px transparent;
      color: $text-blue;
    }
  }
}

.swiper_btn {
  padding: 6px;
  background-color: white;
  border-radius: 100%;
  img {
    max-width: 50px;
  }
  &:last-child {
    img {
      rotate: 180deg;
    }
  }
}

.swiper_btn {
  cursor: pointer;
}

.img_main {
  width: 60%;
  margin: 0 auto;
  img {
    border-radius: 30px;
  }
}

@media (max-width: 1399.98px) {
  .professionals_main {
    padding: 100px 48px;
  }
}

@media (max-width: 1199.98px) {
  .professionals_main {
    padding: 100px 24px;
  }

  .img_main {
    width: 75%;
    margin: 0 auto;
  }
}

@media (max-width: 991.98px) {
  .professionals_main {
    padding: 80px 24px;
  }
  .img_main {
    width: 65%;
    margin: 0 auto;
  }
}

@media (max-width: 767.98px) {
  .left_main {
    h3 {
      font-size: 44px;
      margin-bottom: 30px;
    }
  }
  .professionals_main {
    padding: 60px 20px;
  }

  .swiper_btn {
    img {
      max-width: 40px;
    }
  }
}

@media (max-width: 575.98px) {
  .left_main {
    h3 {
      font-size: 34px;
      margin-bottom: 20px;
    }
  }
  .professionals_main {
    padding: 40px 20px;
  }
}

@media (max-width: 375px) {
  .left_main {
    h3 {
      font-size: 24px;
    }
  }
}
