@import "./variables.scss";

.main {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(9, 9, 9, 0) 50%, #090909bf 100%);
}

// Design Details

.detailMain {
  padding: 60px 0;
}

.bannerContainer {
  position: relative;
  margin: 0 -24px;
}

.infoBox {
  background-color: hsl(0, 0%, 9%);
  border-radius: 20px;
  padding: 70px;
  margin: 48px 0;
  .heading {
    font-family: "Arimo", sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .text {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    p {
      color: #959595;
      font-size: 18px;
      margin-bottom: 0;
    }
  }
}

.imagesContainer {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
}

.pointsMain {
  border-radius: 20px;
  padding: 20px 20px 20px 30px;
  background: $gradient-background;
  .pointsText {
    font-size: 18px;
    margin-bottom: 0;
  }
}

.points {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background-color: hsl(0, 0%, 9%);
  border-radius: 20px;
  padding: 70px;
  margin: 48px 0;
  p {
    color: #959595;
    font-size: 18px;
    margin-bottom: 0;
  }
}

.marketType {
  margin-top: 16px;
  font-size: 18px;
}

.marketTypeList {
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  li {
    padding-left: 6px;
  }
}

.logoDesignFoot {
  margin-top: 16px;
  color: #959595;
  font-size: 18px;
  b {
    color: #006eda;
  }
}

.coming_soon_parent {
  position: relative;
  overflow: hidden;
  &:hover {
    .coming_soon {
      opacity: 1;
      top: 0;
      transition: all 500ms ease;
    }
  }
  .coming_soon {
    width: 100%;
    height: 100%;
    top: 100%;
    opacity: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.3);
    span {
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      font-size: 30px;
    }
  }
}

@media (max-width: 1399.98px) {
  .infoBox {
    padding: 50px;
    margin: 30px 0;
  }

  .points {
    padding: 50px;
    margin: 30px 0;
  }
}

@media (max-width: 991.98px) {
  .imagesContainer {
    gap: 20px;
  }

  .detailMain {
    padding: 40px 0;
  }

  .main {
    padding: 40px 0;
  }

  .infoBox {
    padding: 40px;
  }
  .infoBox {
    margin: 30px 0;
  }
}

@media (max-width: 767.98px) {
  .bannerContainer {
    margin: 0 -8px;
  }

  .pointsMain {
    padding: 10px 10px 10px 20px;
    .pointsText {
      font-size: 16px;
    }
  }

  .detailMain {
    padding: 20px 0;
  }

  .main {
    padding: 0;
  }

  .infoBox {
    padding: 30px;
  }
  .imagesContainer {
    grid-template-columns: auto;
    gap: 15px;
  }
  .infoBox {
    margin: 20px 0;
  }
  .points {
    padding: 30px;
    margin: 20px 0;
  }
}

@media (max-width: 575px) {
  .infoBox {
    padding: 16px;
  }

  .infoBox {
    .heading {
      font-size: 24px;
      margin-bottom: 14px;
    }
    .text {
      p {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 375px) {
  .infoBox {
    padding: 20px;
  }
}
