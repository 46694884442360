@import "./variables.scss";

.main {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
}

.rowMain {
  margin-bottom: 20px;
}

.tabMain {
  margin: 0 auto;
  display: flex;
  margin-bottom: 60px;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
  .tabBtn {
    border-radius: 50px;
    button {
      font-size: 18px;
      color: $white-color;
      background: transparent;
      padding: 10px 18px;
      border-radius: 50px;
    }
    &:not(.tabBtnActive) {
      button {
        border: 1px solid rgba($color: #fff, $alpha: 0.2);
      }
    }
  }
  .tabBtnActive {
    button {
      background: $gradient-background;
      border-width: 1px;
      border: transparent;
    }
  }
}

.overlay {
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(9, 9, 9, 0) 50%, #090909bf 100%);
}

@media (max-width: 991.98px) {
  .tabMain {
    margin-bottom: 40px;
  }

  .main {
    padding: 40px 0;
  }
}

@media (max-width: 575.98px) {
  .tabMain {
    margin-bottom: 20px;
  }

  .main {
    padding: 20px 0;
  }
}
