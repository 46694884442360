@import "./variables.scss";

.slide_main {
  width: 100%;
  position: relative;
  .slide_left {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    max-width: 65%;
    scale: 1;
    transition: all 600ms ease;
    img {
      width: 100%;
      height: auto;
    }
    &:hover {
      scale: 1.02;
    }
  }
  .slide_right {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    max-width: 62.7%;
    margin-left: auto;
    transition: all 600ms ease;
    clip-path: polygon(40% 0, 100% 0, 100% 100%, 0% 100%);
    img {
      width: 100%;
      height: auto;
    }
    &:hover {
      scale: 1.02;
      z-index: 1;
    }
  }
}

.main {
  overflow: hidden;
  position: relative;
}

.horizontal_sliders {
  height: calc(100vh - 118px);
  display: flex;
  width: 400%;
  overflow-x: hidden;
}

.panel {
  height: 100%;
  max-height: calc(100vh - 118px);
  min-height: calc(100vh - 118px);
  width: 100%;
  display: flex;
  align-items: stretch;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: visible hidden;
  .heading1,
  .heading2,
  .heading3,
  .heading4,
  .heading5,
  .heading6,
  .heading7 {
    position: relative;
    &::before {
      content: "1";
      position: absolute;
      color: $text-purple;
      font-size: 300px;
      opacity: 0.2;
      font-family: "Kanit", sans-serif;
      left: -15%;
      top: -210%;
      font-weight: 700;
    }
  }
  .heading1 {
    overflow-x: visible;
    &::before {
      content: "1";
      left: -20%;
      top: -210%;
    }
  }
  .heading2 {
    overflow-x: visible;
    &::before {
      content: "2";
      top: -110%;
      left: -25%;
    }
  }
  .heading3 {
    &::before {
      content: "3";
      left: -25%;
    }
  }
  .heading4 {
    &::before {
      content: "4";
      left: -27%;
    }
  }
  .heading5 {
    &::before {
      content: "5";
      left: -27%;
    }
  }
  .heading6 {
    &::before {
      content: "6";
      top: -110%;
      left: -27%;
    }
  }
  .heading7 {
    &::before {
      content: "7";
      left: -27%;
    }
  }
  &:first-child {
    background-image: url(https://mysocialtheory.com/assets/images/panel1-bg.png);
  }
  &:nth-child(2) {
    background-image: url(https://mysocialtheory.com/assets/images/panel2-bg.png);
  }
  &:nth-child(3) {
    background-image: url(https://mysocialtheory.com/assets/images/panel3-bg.png);
  }
  &:nth-child(4) {
    background-image: url(https://mysocialtheory.com/assets/images/panel2-bg.png);
  }
  .panel1 {
    width: 100%;
    display: flex;
    column-gap: 10%;
    position: relative;
    .description {
      max-width: 630px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      span {
        padding-left: 50px;
        font-family: "Arimo", sans-serif;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 2.24px;
        color: #db0030;
      }
      h4 {
        margin: 24px 0 30px;
        padding-left: 50px;
        font-size: 36px;
        font-weight: 400;
        font-family: "Arimo", sans-serif;
        color: $text-blue;
      }
      p {
        padding-left: 80px;
        font-size: 18px;
        font-weight: 400;
        color: $white-color;
        opacity: 0.75;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 5%;
          width: 10%;
          height: 3px;
          background-color: $text-blue;
        }
      }
    }
    .right {
      max-width: 630px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      .texts {
        padding-left: 40px;
        h6 {
          margin: 20px 0;
          font-size: 48px;
        }
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 1.7;
          opacity: 0.75;
          margin-bottom: 36px;
        }
      }
    }
  }

  .points {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 10px;
    li {
      display: flex;
      gap: 8px;
    }
  }

  .bg_img {
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    z-index: -1;
    background: url(https://mysocialtheory.com/assets/images/howwedo-bg.jpg) 18% 50% / auto 100% no-repeat;
    opacity: 0.5;
  }

  .bg_img1 {
    background-image: url(https://mysocialtheory.com/assets/images/howwedo-bg.jpg);
  }

  .bg_img2 {
    width: 75%;
    background-image: url(https://mysocialtheory.com/assets/images/content-creation.jpg);
  }

  .bg_img3 {
    width: 75%;
    background-image: url(https://mysocialtheory.com/assets/images/graphic-design.jpg);
  }

  .bg_img4 {
    width: 75%;
    background-image: url(https://mysocialtheory.com/assets/images/market-research.jpg);
    background-position: 60%;
  }

  .panel2 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .main {
      overflow: visible;
      width: 100%;
      max-width: 600px;
      position: relative;
      .texts {
        padding-left: 40px;
      }
      h6 {
        margin: 20px 0;
        font-size: 48px;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.7;
        opacity: 0.75;
      }
    }
  }
  .panel3 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: visible;
    .main {
      width: 100%;
      max-width: 600px;
      overflow: visible;
      .texts {
        padding-left: 40px;
      }
      h6 {
        margin: 20px 0;
        font-size: 48px;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.7;
        opacity: 0.75;
      }
    }
  }
  .panel4 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: visible;
    .main {
      overflow: visible;
      width: 100%;
      max-width: 600px;
      .texts {
        padding-left: 40px;
      }
      h6 {
        margin: 20px 0;
        font-size: 48px;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.7;
        opacity: 0.75;
      }
    }
  }
  .number {
    position: relative;
    width: 100%;
    max-width: 70px;
    img {
      width: 100%;
      height: auto;
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 28px;
      color: $text-blue;
      font-family: "Arimo", sans-serif;
    }
  }
}

.gobackdesk {
  position: relative;
  cursor: pointer;
  &:hover {
    &::before,
    &::after,
    .texts {
      scale: 1.5;
    }
    .texts {
      .arrowback {
        rotate: 360deg;
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: -70px;
    bottom: -200px;
    width: 300px;
    aspect-ratio: 1;
    border-radius: 30px;
    transform: rotate(45deg);
    background: #e3dbe8;
    z-index: 5;
    scale: 1;
    transition: scale 0.35s 0.1s ease;
  }
  &::after {
    content: "";
    position: absolute;
    left: -60px;
    bottom: -200px;
    width: 300px;
    aspect-ratio: 1;
    border-radius: 30px;
    transform: rotate(60deg);
    background: #723582;
    opacity: 0.4;
    transition: transform 0.35s;
    z-index: 1;
    scale: 1;
    transition: scale 0.35s ease;
  }
  .texts {
    position: absolute;
    top: -100px;
    left: 50px;
    z-index: 49;
    scale: 1;
    transition: scale 0.35s ease;
    span {
      color: #210b39;
      font-weight: 600;
    }
    .arrowback {
      width: 60%;
      margin: 10px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1;
      border-radius: 100%;
      text-align: center;
      border: 1px solid rgba(162, 66, 158, 0.4);
      transition: rotate 0.35s 0.2s ease;
      svg {
        rotate: 180deg;
      }
    }
  }
}

.gobackmob {
  position: fixed;
  width: 140px;
  height: auto;
  left: 0;
  bottom: 10px;
  border-radius: 0 30px 30px 0;
  padding: 10px 20px 10px 10px;
  background: linear-gradient(to right, #f0eef1 0, #cdbfde 100%);
  .texts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: #210b39;
      font-weight: 600;
      font-size: 14px;
    }
    .arrowback {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1;
      border-radius: 100%;
      text-align: center;
      border: 1px solid rgba(162, 66, 158, 0.4);
      transition: rotate 0.35s 0.2s ease;
      rotate: 180deg;
    }
  }
}

.serviceTxt {
  display: none !important;
}

@media (max-width: 1199.98px) {
  .panel {
    .panel1 {
      column-gap: 5%;
      .description {
        max-width: 100%;
        span {
          padding-left: 30px;
          font-size: 20px;
          letter-spacing: 1.6px;
        }
        h4 {
          margin: 18px 0 24px;
          padding-left: 30px;
          font-size: 28px;
        }
        p {
          padding-left: 50px;
          font-size: 16px;
        }
      }
      .right {
        max-width: 100%;
        padding-bottom: 10px;
        .texts {
          padding-left: 0;
          h6 {
            margin: 10px 0;
            font-size: 32px;
          }
          p {
            font-size: 16px;
            line-height: 1.6;
            margin-bottom: 20px;
          }
          ul {
            row-gap: 6px;
            li {
              column-gap: 10px;
              span {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    .panel2 {
      .main {
        max-width: 100%;
        .texts {
          padding-left: 20px;
        }
        h6 {
          margin: 10px 0;
          font-size: 32px;
        }
        p {
          font-size: 16px;
        }
      }
    }
    .panel3 {
      .main {
        max-width: 100%;
        .texts {
          padding-left: 20px;
        }
        h6 {
          margin: 10px 0;
          font-size: 32px;
        }
        p {
          font-size: 16px;
        }
        ul {
          row-gap: 6px;
          li {
            display: flex;
            column-gap: 10px;
            span {
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
      }
    }
    .panel4 {
      .main {
        max-width: 100%;
        .texts {
          padding-left: 20px;
        }
        h6 {
          margin: 10px 0;
          font-size: 32px;
        }
        p {
          font-size: 16px;
        }
        ul {
          row-gap: 6px;
          li {
            column-gap: 10px;
            span {
              font-size: 18px;
            }
          }
        }
      }
    }
    .number {
      max-width: 50px;
      span {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 991px) {
  .bg_img2,
  .bg_img3,
  .bg_img4 {
    display: none;
  }

  .horizontal_sliders {
    height: 100%;
    max-height: 100%;
    min-height: 100%;
  }

  .servicesImg {
    display: none;
  }

  .serviceTxt {
    display: flex !important;
  }

  .main {
    padding: 0 40px;
  }

  .gobackdesk {
    display: none;
  }

  .panel {
    overflow: hidden;
    .heading1,
    .heading2,
    .heading3,
    .heading4,
    .heading5,
    .heading6,
    .heading7 {
      position: relative;
      &::before {
        left: 0;
        top: -100%;
        font-size: 200px;
      }
    }
    .panel1,
    .panel2,
    .panel3,
    .panel4 {
      flex-direction: column;
      row-gap: 40px;
      .main {
        padding: 0;
        .texts {
          padding: 0;
        }
      }
    }
    .panel1 {
      .description {
        span {
          padding-left: 0;
        }
        h4 {
          padding-left: 0;
        }
        p {
          padding-left: 0;
          &::before {
            content: none;
          }
        }
      }
      .bg_img {
        display: none;
      }
    }
  }

  .horizontal_sliders {
    width: 100%;
    flex-direction: column;
    row-gap: 40px;
  }

  .number {
    display: none;
  }
}

@media (max-width: 767px) {
  .panel {
    height: 100%;
    max-height: 100%;
    min-height: 100%;
  }

  .main {
    padding: 0 20px;
  }

  .horizontal_sliders {
    row-gap: 30px;
  }

  .panel {
    .panel1,
    .panel2,
    .panel3,
    .panel4 {
      row-gap: 20px;
    }
  }

  .panel {
    &:first-child,
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      overflow: visible;
      height: max-content;
    }
  }
}

@media (prefers-reduced-motion) {
  .main {
    transform: none !important;
  }
}
