@import "./variables.scss";

.main {
  padding: 180px 0;
  column-gap: 50px;
}

.sidebar_canvas {
  background-color: $background-color !important;
  overflow-y: scroll;
  padding: 50px 20px;
  width: 500px !important;
  .sidebar {
    margin: 0 auto !important;
  }
}

.sidebar_open {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid white;
  svg {
    rotate: 180deg;
    max-width: 40px;
  }
}

.sidebar_close {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid white;
  svg {
    rotate: 180deg;
    max-width: 40px;
  }
}

.sidebar {
  max-width: 415px;
}

.right_main {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 80px;
}

.blog_img {
  margin: 0 auto;
  max-width: 1440px;
  img {
    border-radius: 20px;
    object-fit: cover;
    aspect-ratio: 1.8;
  }
}

.info {
  display: flex;
  align-items: center;
  column-gap: 34px;
  margin-top: 36px;
  div {
    span {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 2.1px;
    }
  }
}

.description {
  margin-top: 40px;
  h3 {
    font-size: 35px;
    font-family: "Arimo", sans-serif;
    font-weight: 700;
  }
  p {
    max-width: 1140px;
    font-size: 18px;
    font-weight: 400;
    color: $text-color;
    letter-spacing: 0.5px;
  }
  a {
    margin-top: 20px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    span {
      color: $text-blue;
      font-size: 15px;
      letter-spacing: 2.1px;
      text-transform: uppercase;
      font-weight: 700;
    }
    svg {
      width: 100%;
      max-width: 24px;
    }
  }
}

@media (max-width: 1199.98px) {
  .main {
    padding: 140px 0;
    column-gap: 30px;
  }

  .info {
    column-gap: 16px;
  }

  .right_main {
    row-gap: 70px;
  }
}

@media (max-width: 991.98px) {
  .main {
    padding: 110px 0;
  }

  .description {
    margin-top: 30px;
    h3 {
      font-size: 30px;
    }
  }

  .right_main {
    row-gap: 60px;
  }

  .info {
    margin-top: 30px;
  }

  .sidebar {
    max-width: 375px;
  }

  .sidebar_canvas {
    width: 450px !important;
  }
}

@media (max-width: 767.98px) {
  .description {
    margin-top: 20px;
    h3 {
      font-size: 26px;
    }
    p {
      font-size: 16px;
    }
  }

  .right_main {
    row-gap: 50px;
  }
}

@media (max-width: 575.98px) {
  .description {
    margin-top: 20px;
    h3 {
      font-size: 22px;
    }
    p {
      font-size: 14px;
    }
  }

  .right_main {
    row-gap: 40px;
  }

  .info {
    margin-top: 20px;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: center;
    div {
      span {
        font-size: 12px;
      }
    }
  }
}
