.name {
  font-size: 35px;
  margin-bottom: 0;
}

.name_logo {
  width: 100%;
  max-width: 35px;
}

.role {
  font-size: 18px;
  margin: 16px 0;
  color: #bac1d4;
}

.title {
  color: #bac1d4;
}

.social_head {
  font-size: 20px;
  font-family: "Arimo", sans-serif;
}

.foot_text {
  width: 100%;
  max-width: 90%;
  p {
    color: #bac1d4;
    font-size: 18px;
    margin-bottom: 0;
  }
}

.s1,
.s2 {
  width: 100%;
  display: flex;
  gap: 30px;
}

@media (max-width: 1199.98px) {
  .name {
    font-size: 30px;
  }
}

@media (max-width: 767.98px) {
  .name {
    font-size: 26px;
  }

  .s1,
  .s2 {
    flex-direction: column;
    justify-content: space-between;
  }
}
