@import "../../assets/css/variables.scss";

.main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: $background-color;
  border: 2px solid white;
  cursor: pointer;
}

@media (max-width: 1199.98px) {
  .main {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 991.98px) {
  .main {
    width: 90px;
    height: 90px;
  }
}

@media (max-width: 767.98px) {
  .main {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 575.98px) {
  .main {
    width: 70px;
    height: 70px;
    font-size: 14px;
  }
}
