$background-color: #0d0d0d;
$background-color-2: #252525;
$background-color-3: #171119;
$border-color-3: #3c3c3c;
$placeholder-color: #585858;
$background-line-color: #1c1c1c;
$white-color: #ffffff;
$text-color: #bac1d4;
$text-purple: #723582;
$text-blue: #006eda;
$gradient-background: linear-gradient(
  133deg,
  #006eda 30.73%,
  #723582 50.52%,
  #db0030 72.92%
);
