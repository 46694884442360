@import "../../assets/css/variables.scss";

.common_btn {
  width: max-content;
  position: relative;
  padding: 20px 40px;
  background-color: #006eda33;
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  letter-spacing: 1.96px;
  border-radius: 50px;
  &::after {
    content: "";
    top: 0;
    left: 0;
    z-index: -1;
    width: 70px;
    height: 100%;
    position: absolute;
    border-radius: 100%;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    background: $gradient-background;
  }
  &:hover {
    &::after {
      width: 100%;
      border-radius: 35px;
    }
  }
}

.subComment {
  margin-left: 110px;
}

.comment {
  column-gap: 30px;
  .comment_detail {
    gap: 0;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 600;
    span {
      color: $text-color;
    }
  }
}

.comment_txt {
  margin-bottom: 16px;
  max-width: 850px;
  font-size: 18px;
  color: $text-color;
  line-height: 1.6;
}
.like_reply {
  display: flex;
  column-gap: 10px;
  span {
    font-size: 14px;
    color: $text-color;
    letter-spacing: 1.68px;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.sidebar_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  .box {
    padding: 40px;
    background-color: #171717;
    border-radius: 20px;
    .heading {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      column-gap: 12px;
      img {
        max-width: 20px;
      }
      h6 {
        margin-bottom: 0;
        font-size: 24px;
        font-weight: 700;
        font-family: "Arimo", sans-serif;
      }
    }
  }
}

.search {
  input {
    width: 100%;
    padding: 8px 4px;
    font-size: 18px;
    color: $text-color;
    background-color: transparent;
    border-width: 0 0 1px 0;
    border-color: #393939;
    &::placeholder {
      font-size: 18px;
      color: $text-color;
    }
    &:focus {
      outline: none;
    }
  }
}

.post {
  display: flex;
  column-gap: 30px;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  .image {
    max-width: 80px;
    img {
      width: 100%;
      aspect-ratio: 1;
      border-radius: 8px;
    }
  }
  .headings {
    max-width: 225px;
    span {
      color: $text-blue;
      font-weight: 500;
      text-transform: uppercase;
    }
    p {
      margin: 8px 0 0;
      font-family: "Arimo", sans-serif;
    }
  }
}

.service_list {
  ul {
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    list-style: none;
    li {
      display: flex;
      column-gap: 10px;
      span {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.4px;
      }
    }
  }
}

.tag {
  padding: 5px 18px;
  border-radius: 3px;
  background-color: #2e2e2e;
  color: #959595;
}

.gallery_img {
  max-width: 96px;
  img {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
}

.pagination_main {
  div {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $text-color;
    border-radius: 100%;
    cursor: pointer;
    svg {
      width: 24px;
    }
  }
}

@media (max-width: 1199.98px) {
  .common_btn {
    padding: 20px 30px;
  }

  .pagination_main {
    div {
      width: 45px;
      height: 45px;
    }
  }

  .sidebar_main {
    row-gap: 50px;
    .box {
      padding: 30px;
      .heading {
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .gallery_img {
    max-width: 80px;
  } 
}

@media (max-width: 767.98px) {
  .comment {
    max-width: 100%;
    column-gap: 20px;
    .comment_detail {
      font-size: 16px;
    }
    .comment_txt {
      font-size: 14px;
    }
  }

  .subComment {
    margin-left: 20px;
  }

  .comment {
    div {
      column-gap: 20px;
      img {
        max-width: 60px;
      }
    }
  }

  .sidebar_main {
    row-gap: 40px;
    .box {
      padding: 20px;
      .heading {
        margin-bottom: 20px;
        column-gap: 8px;
        img {
          max-width: 16px;
        }
        h6 {
          font-size: 20px;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .common_btn {
    padding: 20px;
    font-size: 14px;
  }
  .comment {
    column-gap: 30px;
    .comment_detail {
      margin-bottom: 0;
    }
  }

  .pagination_main {
    div {
      width: 35px;
      height: 35px;
      span {
        font-size: 14px;
      }
      svg {
        width: 18px;
      }
    }
  }

  .sidebar_main {
    row-gap: 30px;
    .box {
      .heading {
        margin-bottom: 14px;
      }
    }
  }

  .post {
    column-gap: 14px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .image {
      max-width: 60px;
    }
    .headings {
      max-width: 240px;
      span {
        font-size: 14px;
      }
      p {
        font-size: 12px;
        margin: 4px 0 0;
      }
    }
  }

  .tag {
    padding: 3px 12px;
  }
}
